const axios = require('axios');

function clickProductBooth(productId) {
    let datetime = Math.floor(Date.now() / 1000);

    let dateNow = new Date(datetime * 1000);
    let date = dateNow.getDate();
    let month = dateNow.getMonth() + 1;
    let year = dateNow.getFullYear();

    if (!data_product[date + '-' + month + '-' + year]) {
        data_product[date + '-' + month + '-' + year] = {};
    }

    if (!data_product[date + '-' + month + '-' + year]['id-' + productId]) {
        data_product[date + '-' + month + '-' + year]['id-' + productId] = {
            last_view: datetime,
            view: 0
        };
    }
    data_product[date + '-' + month + '-' + year]['id-' + productId]['last_view'] = datetime;
    data_product[date + '-' + month + '-' + year]['id-' + productId]['view'] += 1;

    console.log(data_product)
}

function sendLogBooth(targetType) {

    const data = {
        data_product: data_product
    }

    if (targetType) {
        axios.post('/ajax/view/' + targetType + '/' + targetId, data, {
            headers: {
                'X-CSRF-TOKEN': $("meta[name='_csrf']").attr("content"),
            },
        }).then(function (response) {
            console.log("response", response)

        }).catch(function (error) {
            console.log(error);
        });
    }
}

module.exports = {clickProductBooth, sendLogBooth}